import React from "react"
// import Hero from "../layouts/hero"
import usePosts from "../hooks/use-posts"
// import { Link } from "gatsby";

import Layout from "../layouts/layout"
// import Image from "../components/image";
import SEO from "../components/seo"
import { css } from "styled-components"
import { Heading } from "../components"
import PostPreview from "../layouts/post-preview"
const BlogPage = () => {
  const posts = usePosts()
  return (
    <>
      <Layout>
        <SEO title="Blog" />
        <div
          css={css`
            position: relative;
            max-width: 960px;
            margin: auto;
            padding: 40px 0px;
          `}
        >
          <div>
            <Heading as="h1" variant="h1" level={1}>
              Blog Posts
            </Heading>
            {posts.map(post => (
              <PostPreview key={post.slug} post={post} />
            ))}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BlogPage
